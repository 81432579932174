import React, {Component} from 'react';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import 'tachyons';
import { Button } from '@material-ui/core';
import './App.css';
import Particles from 'react-particles-js';
import Card from './Card.js'
import linkeding from './linkeding.png';
import mailg from './mailg.png';
import gitinvert from './gitinvert.png';
import favi1 from "./favi1.jpg";
import scheduling from "./scheduling.png";
import face_detection from "./face-detection.png";
import chat from "./chat.png";
import todolist from "./to-do-list.png";
import captures2 from "./captures2.PNG";

const particleOptions = {
  particles: {
    number: {
      value: 50,
      density: {
        enable: true,
        value_area: 1000,
      }
    },
    line_linked: {
      shadow: {
        enable: true,
        color: "#f21818",
        blur: 5
      }
    },
  }
}

class App extends Component{
  render(){
    return (
      <div>
        <div className="flex flex-column items-center justify-center vh-100 bg-washed-blue">
          <Particles className='particles' params={particleOptions}/>
          <div className="f2 tc">
            <Zoom duration={1000} top>
              <h1 >
                Aman Gupta
              </h1>
            </Zoom>
          </div>
          <div className="">
            <div className="flex flex-wrap items-center justify-center">
              <div className="pa3">
                <Zoom  delay={1000} duration={350}>
                  <Button style={{color: "#ffffff", textTransform:"none", backgroundColor: "#0A66C2",fontSize: "18px",}}variant="contained" href="https://www.linkedin.com/in/justamangupta/">
                    <img src={linkeding} alt="M1e" height="25px" />&nbsp;LinkedIn
                  </Button>
                </Zoom>
              </div>
              <div className="pa3">
                <Zoom delay={1250} duration={350}>
                  <Button style={{color: "#ffffff", textTransform:"none", backgroundColor: "#EA4335",fontSize: "18px",}}variant="contained" href="mailto:amangupta8086@gmail.com">
                    <img src={mailg} alt="M2e" height="25px" />&nbsp;E-mail 
                  </Button>
                </Zoom>
              </div>
              <div className="pa3">
                <Zoom delay={1500} duration={350}>{/*#32B155*/}
                  <Button style={{color: "#ffffff", textTransform:"none", backgroundColor: "#000000",fontSize: "18px",}}variant="contained" href="https://github.com/justamangupta">
                  <img src={gitinvert} alt="M3e" height="25px" />&nbsp;{"Github"}
                  </Button>
                </Zoom>
              </div>
            </div>
          </div>
        </div>

        <Button className="w-100" style={{
          color: "#ffffff",
          fontWeight:"lighter",
            backgroundColor: "#03cefc",
            fontSize:"100%",
            textTransform:"none"
        }}>
          <div className="justify-center items-center vh-100 About">
            <div className="pa4">
              <div className=" ">
                <Zoom bottom>
                  <img className="roundimg" src={favi1} alt="Me" height="250px" width="250px"/>
                </Zoom> 
              </div>
              <Zoom top>
                <img  src={captures2} alt="M2e"/>   
                {/*<p className="items-center flex flex-wrap">
                I am a third year undergraduate student working towards a Bachelor's degree in Electronics and Communication
                Engineering at Vellore Institute of Technology, Chennai. I've an immense interest in Computer Science. 
                I am always very eager to learn and determined to complete whatever I start. And this very attitude has 
                helped me in being able learn and become skilled at Computer Science related subjects and skills while 
                performing well in my major of Electronics.
                I believe in keeping a positive and humble attitude and I get on well with all kinds of people.
                </p>*/}
              </Zoom>
            </div>
          </div>
        </Button>

        <Button className="w-100 items-center" style={{
            backgroundColor: "#ffffff",
            fontSize: "18px",textTransform:"none",
        }}>
          <div className="flex flex-column items-center vh-100 w-100 Project">
            <div className="pa4">
              <Fade distance="200%" duration={1000} top>
                <h1 className="f1 dark-green">
                  Projects
                </h1>  
              </Fade> 
              <div className="justify-center flex flex-row flex-wrap">
                <Zoom left>
                  <Card name="College Attendence App&nbsp;" source={scheduling} alta="first"/>
                </Zoom>
                <Zoom top>
                  <Card name="Face Regonition Web App" source={face_detection} alta="second"/>
                </Zoom>
                <Zoom bottom>
                  <Card name="&nbsp;&nbsp;Real Time Chat App&nbsp;&nbsp;&nbsp;" source={chat} alta="third"/>
                </Zoom>
                <Zoom right>
                  <Card name="Cross Platform ToDo App" source={todolist} alta="forth"/>
                </Zoom>
              </div>
              <div className="f5 ">
                <h1>
                  More at GitHub!
                </h1>
              </div>
            </div>
          </div>
        </Button>

      </div>
    );
  }
}

export default App;
