import React from 'react';
import './Card.css'

const Card = ({ name, source, alta}) => {
    return (
        <div className='tc dib br3 pa3 ma2 grow bw2 shadow-5 Card'>
            <img alt={alta} height="100px" src={source} href="https://github.com/justAmanGupta/ToDo-App" />
            <div>
                <p>{name}</p>
                <p></p>
            </div>
        </div>
    );
}

export default Card;